import { Image } from 'components/ImageComponent';
import Link from 'next/link';
import { FC } from 'react';

import { HotelType } from 'types/types';

interface IHotelCard {
  hotel: HotelType;
}

interface ICardProps {
  href: string;
  name: string;
  image: string;
}

const Card = ({ href, name, image }: ICardProps) => {
  return (
    <a className="hotel-card-container" href={href}>
      <Image src={image} alt={name} style={{ width: '100%', height: '100%' }} />
      <div className="hotel-card-container__gradient-layer" />
      <div className="hotel-card-container__description-text">{name}</div>
    </a>
  );
};

const HotelCard: FC<IHotelCard> = ({ hotel: { name, image, hotel_id } }) => {
  return (
    <Link href={`/our-hotels/${hotel_id}`} passHref>
      <Card href={`/our-hotels/${hotel_id}`} name={name} image={image} />
    </Link>
  );
};

export default HotelCard;
