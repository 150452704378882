// import { FC, useCallback } from 'react';
import { FC } from 'react';
// import { useRouter } from 'next/router';
import Image from 'next/image';
// import Button, { OUTLINED_BUTTON_TYPE_DARK } from 'components/Button/Button';
import useLocalised from 'customHooks/useLocalised/useLocalised';
// import { 
//   find_your_next_luxury,
//   explore_hotels, 
//   explore_our_destinations 
// } from 'data/translationKeys';
import { find_your_next_luxury } from 'data/translationKeys';
import Divider from 'components/icons/Divider';
import GlobalPresenceMap from 'blocks/GlobalPresence/assets/map.jpeg';
// import { navigationPaths } from 'data/constants';

const GlobalPresence: FC = () => {
  const find_your_next_luxury_text = useLocalised(find_your_next_luxury);
  {/* TODO: Comment it back when the page is ready */}
  // const explore_hotels_text = useLocalised(explore_hotels);
  // const explore_our_destinations_text = useLocalised(explore_our_destinations);
  // const router = useRouter();

  // const handleClickOnExploreHotels = useCallback(() => {
  //   router.push(navigationPaths.OUR_HOTELS);
  // }, [router]);

  return (
    <div className="global-presence">
      <div className="global-presence-up-component">
        <div className="global-presence-up-component__container">
          <div className="top-section">
            <div className="top-section__divider">
              <Divider />
            </div>
            <div className="top-section__text">
              {find_your_next_luxury_text}
            </div>
            {/* TODO: Comment it back when the page is ready */}
            <div className='top-section__button'>
              {/* <Button 
                title={explore_hotels_text} 
                onClick={handleClickOnExploreHotels} 
                buttonType={OUTLINED_BUTTON_TYPE_DARK} 
                className={'view-membership__button'}
              /> */}
            </div>
          </div>
        </div>
      </div>
      <div className="global-presence-bottom-component">
        <div className="global-presence-bottom-component__container">
          <div className="bottom-section">
            <div className="bottom-section__image">
              <Image
                src={GlobalPresenceMap}
                alt={"Global_Presence_Map"}
                layout="responsive"
                width={1000}
                height={500}
              />
            </div>
            <div className='bottom-section__button'>
              {/* TODO: Comment it back when the page is ready */}
              {/* <Button 
                title={explore_our_destinations_text}
                onClick={handleClickOnExploreHotels} 
                buttonType={OUTLINED_BUTTON_TYPE_DARK} 
                className={'view-membership__button'}
              /> */}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default GlobalPresence;
