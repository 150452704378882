import Container from 'components/container';
import React, { FC } from 'react';
import style from './HomePageLink.module.scss';
import RosewoodCover1 from 'blocks/MylerSection/assets/myler-homepage-cover-1.jpeg';
import RosewoodCover2 from 'blocks/MylerSection/assets/myler-homepage-cover-2.jpeg';
import RosewoodCover3 from 'blocks/MylerSection/assets/myler-homepage-cover-3.jpeg';
import { useRouter } from 'next/router';
import { Body, Heading } from 'components/text';
import Button from 'components/Button/Button';
import Image from 'next/image';
import { navigationPaths } from 'data/constants';
type IHomePageLink = {};
const HomePageLink: FC<IHomePageLink> = ({}) => {
  const router = useRouter();

  const goToPage = () => {
    router.push(navigationPaths.DISCOVER_MYLER);
  };

  return (
    <Container className={style.container}>
      <div className={style.contentWrapper}>
        <div className={style.mainCover}>
          <Image src={RosewoodCover1} alt="Rosewood baha mar hotel room" />
        </div>

        <div className={style.descriptionWrapper}>
          <Heading as="h4" className={style.descTitle}>
            You’re here because you love luxury travel.
          </Heading>
          <Heading as="h5" className={style.descSubtitle}>
            So, why not turn it into a career?
          </Heading>

          <div className={style.descriptionContainer}>
            <Body className={style.description}>
              Whether it’s a full-time business or a lucrative side-hustle, our
              Independent Travel Advisor programme lets you earn income while
              working from anywhere, anytime.
            </Body>

            <Button
              className={style.learnMoreBtn}
              title={'Learn more'}
              onClick={goToPage}
            />
            <div className={style.imagesContainer}>
              <Image src={RosewoodCover2} alt="Rosewood baha mar hotel room" />
              <Image
                src={RosewoodCover3}
                alt="Rosewood baha mar hotel pool area"
              />
            </div>
          </div>
        </div>
      </div>
    </Container>
  );
};

export default HomePageLink;
