import { FC } from 'react';

import HotelCard from 'blocks/HotelCard/HotelCard';
import { HotelType } from 'types/types';

interface IHotelList {
  ourHotels: HotelType[];
}

const HotelList: FC<IHotelList> = ({ ourHotels }) => {
  return (
    <div className="hotel-list-container">
      {ourHotels.map(hotel => (
        <HotelCard key={hotel.id} hotel={hotel} />
      ))}
    </div>
  );
};

export default HotelList;
