import useMediaQuery from '@mui/material/useMediaQuery';
import { Image } from 'components/ImageComponent';
import { FC } from 'react';

import InstagramIcon from 'components/icons/InstagramIcon';
import useLocalised from 'customHooks/useLocalised/useLocalised';
import { breakpoints, colorConstants } from 'data/constants';
import {
  instagram,
  instagram_section_description,
  instagram_section_title,
} from 'data/translationKeys';
import { IntagramImageType } from 'types/types';

interface IInstagram {
  instagramImages: IntagramImageType[];
}

const Instagram: FC<IInstagram> = ({ instagramImages }) => {
  const instagram_text = useLocalised(instagram);
  const instagram_section_title_text = useLocalised(instagram_section_title);
  const instagram_section_description_text = useLocalised(
    instagram_section_description
  );

  const isMobile = useMediaQuery(`(max-width:${breakpoints.sm})`);

  return (
    <div className="instagram-section-container">
      {!isMobile && (
        <div className="instagram-section-container__title">
          <InstagramIcon color={colorConstants.black} />
          {instagram_section_title_text + ' '}
          {instagram_text}
        </div>
      )}
      {isMobile && (
        <div className="instagram-section-container__title">
          <div className="mobile-view">
            <div>{instagram_section_title_text}</div>
            <div className="mobile-view__bottom">
              <div className="icon">
                <InstagramIcon color={colorConstants.gray} />
              </div>
              <div>{instagram_text}</div>
            </div>
          </div>
        </div>
      )}
      <div className="instagram-section-container__description">
        {instagram_section_description_text}
      </div>
      <div className="instagram-section-container__images">
        {instagramImages.map((image, index) => (
          <div key={index} className={`insta-image-${index + 1}`}>
            <a href={image.permalink} target="_blank" rel="noreferrer">
              <Image
                src={image.url.replace(/^[^.]*/, 'https://scontent-lhr8-1')}
                alt={'instagram post'}
              />
            </a>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Instagram;
