import { FC } from 'react';
import Image from 'next/image';

import useLocalised from 'customHooks/useLocalised/useLocalised';
import { download_the_app } from 'data/translationKeys';
import { externalNavigationPaths, externalLinkTitles } from 'data/constants';
import Divider from 'components/icons/Divider';
import DownloadFromAppStore from 'blocks/DownloadApp/assets/appstore.png';
import DownloadFromGooglePlay from 'blocks/DownloadApp/assets/googleplay.png';

const DownloadApp: FC = () => {
  const download_the_app_text = useLocalised(download_the_app);

  return (
    <div className="download-app-component">
      <div className="download-app-component__container">
        <div className="download-app">
          <div className="download-app__divider">
            <Divider />
          </div>
          <div className="download-app__text">
            {download_the_app_text}
          </div>
          <div className="download-app__buttons">
            <a
              href={externalNavigationPaths.APP_STORE}
              rel="noreferrer"
              target="_blank"
              className="download-app__buttons__store"
              title={externalLinkTitles.APP_STORE_TITLE}
            >
              <Image
                src={DownloadFromAppStore}
                alt={externalLinkTitles.APP_STORE_TITLE}
                layout="responsive"
                width={144}
                height={43}
              />
            </a>
            <a
              href={externalNavigationPaths.PLAY_STORE}
              rel="noreferrer"
              target="_blank"
              className="download-app__buttons__store"
              title={externalLinkTitles.GOOGLE_PLAY_TITLE}
            >
              <Image
                src={DownloadFromGooglePlay}
                alt={externalLinkTitles.GOOGLE_PLAY_TITLE}
                layout="responsive"
                width={144}
                height={43}
              />
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DownloadApp;
