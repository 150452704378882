import { FC } from 'react';
import useLocalised from 'customHooks/useLocalised/useLocalised';
import Divider from 'components/icons/Divider';
import { colorConstants, elementIds } from 'data/constants';
import {
  why_little_emperors,
  why_little_emperors_description,
  access_the_best_prices,
  access_the_best_prices_content,
  enjoy_complimentry_benefits,
  enjoy_complimentry_benefits_content,
  relax_and_switch_off,
  relax_and_switch_off_content,
} from 'data/translationKeys';

const WhyLittleEmperors: FC = () => {

  const why_little_emperors_text = useLocalised(why_little_emperors);
  const why_little_emperors_description_text = useLocalised(why_little_emperors_description);
  const access_the_best_prices_text = useLocalised(access_the_best_prices);
  const access_the_best_prices_content_text = useLocalised(access_the_best_prices_content);
  const enjoy_complimentry_benefits_text = useLocalised(enjoy_complimentry_benefits);
  const enjoy_complimentry_benefits_content_text = useLocalised(enjoy_complimentry_benefits_content);
  const relax_and_switch_off_text = useLocalised(relax_and_switch_off);
  const relax_and_switch_off_content_text = useLocalised(relax_and_switch_off_content);

  return (
    <div className='why-little-emperors' id={elementIds.WHY_LITTLE_EMPERORS_SECTION_ID}>
      <div className='why-little-emperors__title'>{why_little_emperors_text}</div>
      <div className='why-little-emperors__description'>
        <h5 className='why-little-emperors__text'>{why_little_emperors_description_text}</h5>
      </div>
      <div className='why-little-emperors__content'>
        <div className='content-box'>
          <div className='content-box__text'>
            <h5 className='content-box__title'>{access_the_best_prices_text}</h5>
            <div className='content-box__content'>{access_the_best_prices_content_text}</div>
          </div>
          <div className='content-box__text'>
            <h5 className='content-box__title'>{enjoy_complimentry_benefits_text}</h5>
            <div className='content-box__content'>{enjoy_complimentry_benefits_content_text}</div>
          </div>
          <div className='content-box__text'>
            <h5 className='content-box__title'>{relax_and_switch_off_text}</h5>
            <div className='content-box__content'>{relax_and_switch_off_content_text}</div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default WhyLittleEmperors;
