import { FC, SVGProps } from 'react';
import { colorConstants } from 'data/constants';

interface IHeroArrowDown extends SVGProps<SVGSVGElement> {
  color?: typeof colorConstants.black | typeof colorConstants.white | typeof colorConstants.brandColor | typeof colorConstants.gray;
}

const HeroArrowDown: FC<IHeroArrowDown> = ({
  color = colorConstants.black,
}) => {
  return (
    <svg width="24" height="14" viewBox="0 0 24 14" fill="none" xmlns="http://www.w3.org/2000/svg">
      <title>Scroll down</title>
      <path d="M23.7865 1.25025L22.6843 0.145756L12.0303 10.8222L1.37634 0.145752L0.27417 1.25025L12.0303 13.0312L23.7865 1.25025Z" fill={color}/>
    </svg>
  );
};

export default HeroArrowDown;
