import { FC, useState, useEffect, useCallback  } from 'react';
import IconButton from '@mui/material/IconButton';

import useLocalised from 'customHooks/useLocalised/useLocalised';
import { we_guarantee_the_lowest_rates } from 'data/translationKeys';
import Divider from 'components/icons/Divider';
import { colorConstants, elementIds, HERO_SECTION_TIME_INTERVAL } from 'data/constants';
import HeroArrowDown from 'components/icons/HeroArrowDown';
import HeroTitleLeftDecorator from 'components/icons/HeroTitleLeftDecorator';
import HeroTitleRightDecorator from 'components/icons/HeroTitleRightDecorator';
import { HotelImageType } from 'types/types';

interface IHeroSection {
  heroElements: HotelImageType[],
  hasTextBox?: boolean,
}

type HeroSectionStateType = {
  index: number,
  hotelText: string,
};

const HeroSection: FC<IHeroSection> = ({
  heroElements,
  hasTextBox = true,
}) => {
  const [state, setState] = useState<HeroSectionStateType>({
    index: 0,
    hotelText: heroElements[0].hotel,
  });

  const handleScrollDown = useCallback(() => {
    const nextSection = document.getElementById(elementIds.WHY_LITTLE_EMPERORS_SECTION_ID);
    if (nextSection) {
      (nextSection as HTMLDivElement).scrollIntoView({
        behavior: "smooth",
      });
    }
  }, []);

  // Preloading images
  const [preloadedImageCount, setPreloadedImageCount] = useState(0);
  useEffect(() => {
    heroElements.forEach(({ image: imageUrl }) => {
      const img = new Image();

      img.onload = () => {
        setPreloadedImageCount(prev => prev + 1);
      };

      img.src = imageUrl;
    });
  }, [heroElements]);

  useEffect(() => {
    if (preloadedImageCount < heroElements.length) {
      return;
    }
    let index = 1;
    const timeInterval = setInterval(() => {
      setState({
        index,
        hotelText: heroElements[index].hotel,
      });
      index += 1;
      if (index === heroElements.length) {
        index = 0;
      }
    }, HERO_SECTION_TIME_INTERVAL);
    return (() => {
      clearInterval(timeInterval);
    });
  }, [heroElements, preloadedImageCount]);

  const we_guarantee_the_lowest_rates_text = useLocalised(we_guarantee_the_lowest_rates);
  return (
    <>
      <div className="hero-section-container">
        {heroElements.map(({ id, image }, index) => (
          <div
            key={id}
            className="hero-section-container__background-layer"
            style={{ backgroundImage: `url("${image}")`, opacity: state.index === index ? 1 : 0 }}
          />
        ))}
        <div className="hero-section-container__gradient-layer" />
        <div className="hero-section-container__inner">
          {hasTextBox && (
            <div className="hero-text-box">
              {we_guarantee_the_lowest_rates_text}
              <Divider color={colorConstants.brandColor} />
            </div>
          )}
          <div className="hero-hotel-name-box">
            {state.hotelText}
          </div>
        </div>
        <IconButton className="hero-section-container__down-button" onClick={handleScrollDown}>
          <HeroArrowDown />
        </IconButton>
      </div>
      {hasTextBox && (
        <div className="hero-section-container-mobile">
          <div className="hero-section-container-mobile__top">
            <div className="hero-text-box-mobile__left">
              <HeroTitleLeftDecorator />
            </div>
            <div className="hero-text-box-mobile__center">
              {we_guarantee_the_lowest_rates_text}
            </div>
            <div className="hero-text-box-mobile__right">
              <HeroTitleRightDecorator />
            </div>
          </div>
          <div className="hero-section-container-mobile__bottom">
            <IconButton className="hero-section-scroll-down-button" onClick={handleScrollDown}>
              <HeroArrowDown color={colorConstants.white} />
            </IconButton>
          </div>
        </div>
      )}
    </>
  )
}

export default HeroSection
