import { FC, SVGProps } from 'react';
import { colorConstants } from 'data/constants';

interface IDividerIcon extends SVGProps<SVGSVGElement> {
  color?: typeof colorConstants.black | typeof colorConstants.white | typeof colorConstants.brandColor | typeof colorConstants.gray;
  small?: boolean
}

const Divider: FC<IDividerIcon> = ({
  color = colorConstants.gray,
  small = false,
}) => {
  return (
    <div className="divider">
      {small ? (
        <>
          <div className="divider__line" style={{ backgroundColor: color}} />
          <div className="divider__circles">
            <svg width="14" height="9" viewBox="0 0 14 9" fill="none" xmlns="http://www.w3.org/2000/svg">
              <circle cx="4.5" cy="4.5" r="4" stroke={color}/>
              <circle cx="9.5" cy="4.5" r="4" stroke={color}/>
            </svg>
          </div>
          <div className="divider__line" style={{ backgroundColor: color}} />
        </>
      ) : (
        <>
          <div className="divider__line" style={{ backgroundColor: color}} />
          <div className="divider__circles">
            <svg width="30" height="19" viewBox="0 0 30 19" fill="none" xmlns="http://www.w3.org/2000/svg">
              <circle cx="9.5" cy="9.5" r="9" stroke={color}/>
              <circle cx="20.5" cy="9.5" r="9" stroke={color}/>
            </svg>
          </div>
          <div className="divider__line" style={{ backgroundColor: color}} />
        </>
      )}
    </div>
  );
}

export default Divider;
