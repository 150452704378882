import {
  FC,
  // useCallback,
} from 'react';
// import { useRouter } from 'next/router';

import HotelList from 'blocks/HotelList/HotelList';
// import Button, { OUTLINED_BUTTON_TYPE_DARK } from 'components/Button/Button';
import Divider from 'components/icons/Divider';
import useLocalised from 'customHooks/useLocalised/useLocalised';
import {
  // navigationPaths,
  colorConstants,
} from 'data/constants';
import {
  our_hotels,
  our_hotels_section_description,
  // explore_hotels,
} from 'data/translationKeys';
import { HotelType } from 'types/types';

interface IOurHotels {
  ourHotels: HotelType[],
};

const OurHotels: FC<IOurHotels> = ({
  ourHotels,
}) => {
  {/* TODO: Comment it back when the page is ready */}
  // const router = useRouter();
  // const handleExploreOurHotelsClick = useCallback(() => {
  //   router.push(navigationPaths.OUR_HOTELS);
  // }, [router]);

  const our_hotels_text = useLocalised(our_hotels);
  const our_hotels_section_description_text = useLocalised(our_hotels_section_description);
  // const explore_hotels_text = useLocalised(explore_hotels);

  return (
    <div className="out-hotels-container">
      <div className="out-hotels-container__title">
        {our_hotels_text}
      </div>
      <div className="out-hotels-container__description">
        {our_hotels_section_description_text}
      </div>
      <HotelList ourHotels={ourHotels} />
      {/* TODO: Comment it back when the page is ready */}
      {/* <Button
        title={explore_hotels_text}
        onClick={handleExploreOurHotelsClick}
        buttonType={OUTLINED_BUTTON_TYPE_DARK}
        className="out-hotels-container__button"
      /> */}
      <div className='divider'><Divider color={colorConstants.brandColor} /></div>
    </div>
  );
};

export default OurHotels;
