import Image from 'next/image';

import { Body, Heading } from 'components/text';

import styles from './BuyVoucher.module.scss';

import holidayStars from 'assets/holiday-stars.png';
import holidayStarsMobile from 'assets/holiday-stars-mobile.png';

import BlackGiftCard from 'assets/svg/BlackGiftCard.svg';
import { useMediaQuery } from '@mui/material';
import Container from 'components/container';

import Link from 'next/link';

const BuyVoucher = () => {
  const isMobile = useMediaQuery('(max-width: 768px)');
  return (
    <div className={styles.container}>
      <div className={styles.imageContainer}>
        <Image
          draggable={false}
          src={isMobile ? holidayStarsMobile : holidayStars}
          alt="holiday stars"
          objectPosition="top"
          objectFit="cover"
          layout="fill"
        />

        <div className={styles.contentContainer}>
          <BlackGiftCard />

          <div className={styles.content}>
            <Container mt={60}>
              <Heading as="h5" color="white">
                Share the joy of luxury travel with{' '}
              </Heading>
              <Heading as="h3" color="white" mt={20}>
                Little Emperors e-gift card
              </Heading>
            </Container>

            <Link href="/e-gift-card">
              <a>
                <Body color="white" weight="demiBold">
                  Buy now
                </Body>
              </a>
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BuyVoucher;
